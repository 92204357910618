
<template>
    <div>

      <template>
      <div id="breadcrumb-alignment" class="mb-1">
        <div class="d-flex justify-content-start breadcrumb-wrapper">
          <b-breadcrumb
            :items="breadCrumb()"
          />
        </div>
      </div>
    </template>

      <b-card 
        :title="item.subject" v-if="item != null">
        
        <!-- <b-form @submit="formSubmit"> -->
          <b-row>
            <b-col>
              <ckeditor v-model="item.body" :config="editorConfig"></ckeditor> <br>
              <p class="text-danger">* Please do not change or remove contents written in {}.</p>
              </b-col>
          </b-row> 
          <br> 
          <b-row>
            <b-col>
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                type="button"
                variant="warning"
                class="mr-1"
                @click="formSubmit"
              >
                Submit
              </b-button>
              <b-button
                v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                variant="outline-secondary"
                @click="$router.push({ name: 'email-list'})"
              >
                Cancel
              </b-button>
            </b-col>
          </b-row>
        <!-- </b-form> -->
      </b-card>
          
    </div>
</template>

<script>
// require styles
/*import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'
*/
import CKEditor from 'ckeditor4-vue';
import {
  BCard, 
  BButton,
  BCol,
  BRow,
  BForm,
  BBreadcrumb
} from 'bootstrap-vue';
import { GET_API, POST_API } from "../../../store/actions.type"
import moment from "moment";
import Ripple from 'vue-ripple-directive'

export default {
  data () {
    return {
      item:null,
      editorConfig: {
          removeButtons:'Maximize',
          extraPlugins: 'copyformatting,colorbutton,bidi,colordialog,widget,justify',
          height:'400px'
          },
      coreStyles_bold: {
          element: 'b',
          overrides: 'strong'
      },
        // Custom style definition for the Italic feature.
      coreStyles_italic: {
          element: 'i',
          overrides: 'em'
      }
    }
  },
  directives: {
    Ripple,
  },
  components: {
   
    BCard, 
    BButton,
    BCol,
    BRow,
    BForm,
    BBreadcrumb
  },
  methods : {
    formSubmit(){

      return this.$store.dispatch(POST_API, {
           data:{
             id:this.item._id,
             body:this.item.body
           },
           api: '/api/update-email-template'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Updated Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name: 'email-list'});
                });
                
            }
        });
    },
    emailDetail(){
      
      return this.$store.dispatch(POST_API, {
           data:{
              id:this.$route.params.id
             },
           api: '/api/get-email-detail'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
              this.showDismissibleAlert = false;
              this.item = this.$store.getters.getResults.data;
            
              return this.item;
            }
        });
    },

    breadCrumb(){
      var item = [{
        to:{name:'admin-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'DataBase',
      },{
        to:{name:'email-list'},
        text: 'Email Templates',
      },{
        to:null,
        text: 'Edit Template',
        active:true             
      }];
      return item;
    },

  },
  mounted(){
    this.emailDetail();
  }
}
</script>
